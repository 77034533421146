<template>
  <div style="margin: -15px -15px">
  <a-spin :spinning="spinning">
  <div class="CaseSettlement">
    <div class="CaseTemplate">
      <h4 style="padding: 10px">病例模板</h4>
      <a-menu
          style="width: 230px"
          mode="inline"
          v-model="menu"
      >
        <a-menu-item
            v-for="(item,index) in CaseTemplates"
            :key="index"
            @click="leftChange(item.id,item.adminLink)"
        >
          {{item.title}}
        </a-menu-item>

      </a-menu>
    </div>
    <!--病例表格-->
    <div class="CaseData" >
      <div class="CaseTables">
        <div style="display: flex;justify-content: space-between;margin-bottom: 10px"  >
          <div>
            状态: &nbsp;<a-select  v-model="selectValue" style="width: 120px;" @change="settlementChange">
            <a-select-option value="0">
              待打印
            </a-select-option>
            <a-select-option value="1">
              已打印
            </a-select-option>
          </a-select></div>
          <div>
            上传时间: &nbsp;<a-range-picker @change="dateChange" style="width: 220px" />
           &nbsp;<a-button @click="searchOk"> 查询</a-button>
          </div>
          <div style="width: 250px;display: flex;align-items: center;justify-content: center;height: 32px;background-color: rgba(230, 247, 255, 1);border: rgba(145, 213, 255, 1) 1px solid;border-radius: 5px">
            <a-icon type="info-circle" theme="twoTone" />&nbsp;待打印:   {{caseCount.toBeCount}}  &nbsp;  已打印:   {{caseCount.alreadyCount}}
          </div>
        </div>
        <div style="display: flex">
        <div style="width: 400px;display: flex;justify-content: space-between;margin-bottom: 10px">
          <a-button @click="dateOk" ><a-icon type="printer" style="color: #1890ff" />批量标记打印</a-button>
          <a-button  ><a-icon type="file-word" style="color: darkblue" />批量下载</a-button>
          <a-button @click="exportOk" ><a-icon type="file-excel" style="color: #00CC33" />批量导出</a-button>
        </div>
        </div>
        <a-table :columns="columns" :data-source="CaseTable" rowKey="created_time"  :pagination="false"
        >
      <span slot="Doctor" slot-scope="text,record">
          {{record.memberName}} - {{record.memberHospitalName}}
        </span>
          <span slot="Patient" slot-scope="text,record">
          <span v-html="record.patientSex === 1 ? '男':'女'">{{record.patientSex}}</span>-
          {{record.patientAge}}岁
        </span>
          <span slot="condition" slot-scope="text,record">
            <span style="display: flex;align-items: center" v-if="record.isPrint === 0" ><span style="background-color: #aba9a9;width: 8px;height: 8px;border-radius: 8px"></span>&nbsp; 待打印</span>
            <span style="display: flex;align-items: center" v-if="record.isPrint === 1" ><span style="background-color: #00CC66;width: 8px;height: 8px;border-radius: 8px"></span>&nbsp;已打印</span>
          </span>
          <span slot="switch" slot-scope="text,record">
            <a-switch :defaultChecked="record.isPrint!==0" checked-children="是" un-checked-children="否" @click="switchChange(record.id,record.isPrint === 1? 0 : 1)" />
          </span>
          <span slot="operate"  slot-scope="text,record">
            <span style="color: #4a9bfa;cursor: pointer;" @click="download(record.id)">
              打印
            </span>
          </span>
        </a-table>
        <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 10px">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="CasePagination" />
        </div>
      </div>
    </div>

  </div>
  </a-spin>
  </div>
</template>

<script>
import {
  getCasesTable,
  getCaseTemplates,
  getPrintCount,
  putCaseCondition,
  putCasePrint
} from "@/service/CaseTemplate_api";
import headerConfig from '@/service/api_header_config'
import moment from 'moment'
export default {
  name: "CasePrint",
  data(){
    return{
      searchValue:{
        DayStart1:'',
        DayEnd1:'',
      },
      menu:[0],
      selectValue:'待打印',
      spinning:false,
      CaseTemplates:{
      },
      CaseTable:[],
      changeLeftBackground: 0,
      columns:[
        {
          title: '病例ID',
          dataIndex: 'id',
          width: '8%'
        },
        {
          title: '医生信息',
          scopedSlots: { customRender: 'Doctor' },
          width: '24%'
        },
        // {
        //   title: '患者信息',
        //   scopedSlots: { customRender: 'Patient' },
        //   width: '15%'
        // },

        {
          title: '上传时间',
          dataIndex: 'created_time',
          width: '18%'
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'condition' },
        },
        {
          title: '是否已打印',
          width: '13%',
          scopedSlots: { customRender: 'switch' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },

      ],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      CaseId:null,
      CasePrintValue:0,
      caseCount:{
      },
    }
  },
   created() {
    this.$store.dispatch('setManageHeaderTitle', "病历打印管理")
  },
  async mounted() {
    await this.getCaseAccountList()
    this.CaseId = this.CaseTemplates[0].id
    const data = {
      template_id : this.CaseId,
      isPrint: 0,
      audit_status :  'PASSED',
      isAudit : 1
    }
     await this.getCaseTemplates(data)
     await this.getCaseCount(this.CaseId,'','')
  },
  methods:{

    // 获取病例模板
    async getCaseAccountList(){

      const  data ={
        isPrint : 1
      }
      const result = await getCaseTemplates(data)
      if (result.code === 0){
        this.CaseTemplates = result.data.rows
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }

    },
    // 病例列表
    async getCaseTemplates(data){
      this.spinning = true
      const result = await getCasesTable(data)
      if (result.code === 0){
        this.CaseTable = result.data.rows
        this.pagination.total = result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
     CasePagination(){
      const Page = this.pagination.current
      const data = {
        template_id : this.CaseId,
        isPrint : this.CasePrintValue,
        page_no : Page,
        audit_status :  'PASSED',
        start_time : this.searchValue.DayStart1,
        end_time :this.searchValue.DayEnd1,
        isAudit : 1
      }
       this.getCaseTemplates(data)
    },
    searchOk(){
      const date = this.searchValue
      const data = {
        template_id : this.CaseId,
          isPrint : this.CasePrintValue,
        start_time : date.DayStart1,
        end_time : date.DayEnd1,
        audit_status :  'PASSED',

      }
      this.getCaseCount(this.CaseId,this.searchValue.DayStart1,this.searchValue.DayEnd1)
      this.getCaseTemplates(data)
      this.pagination.current = 1
    },
    //统计数据
    async getCaseCount(template_id,start_time,end_time){
      const res = await getPrintCount(template_id,start_time,end_time)
      if (res.code === 0){
        this.caseCount = res.data
      }
    },
    //批量改变打印状态
    async dateOk(){
      const date = this.searchValue
      const data = {
        start_time : date.DayStart1,
        end_time : date.DayEnd1,
        template_id : this.CaseId
      }
      const dateOk = await putCasePrint(data)
      if (dateOk.code === 0 ){
        this.$message.success('修改成功')
        const data ={
          template_id : this.CaseId,
          isPrint: this.CasePrintValue,
          audit_status :  'PASSED',
          isAudit : 1
        }
        await this.getCaseTemplates(data)
        await this.getCaseCount(this.CaseId)
      }
    },
    //导出病例信息
    exportOk(){
      let url
      if (this.adminLink!=='chdCase'){
        url = headerConfig.api_header + '/api/admin/case/caseReportExcelInfoByCase/'
            + this.CaseId + '?start_time=' + this.searchValue.DayStart1 + '&end_time=' + this.searchValue.DayEnd1 +'&is_print=' + this.CasePrintValue + '&audit_status=PASSED';
      }else {
        url =  headerConfig.api_header + '/case/wenjuan/port?templateId=' + this.CaseId + '&startTime=' + this.searchValue.DayStart1 + '&endTime=' + this.searchValue.DayEnd1+'&status=PASSED';
      }

      window.open(url)
    },
    //查询日期
    dateChange(date, dateString){
      this.searchValue.DayStart1 = dateString[0]
      this.searchValue.DayEnd1 = dateString[1]
    },
    // 状态搜索
     settlementChange(value) {
      this.CasePrintValue = value
      const data ={
        template_id : this.CaseId,
        isPrint: value,
        audit_status :  'PASSED',
        isAudit : 1
      }
       this.getCaseTemplates(data)
       this.pagination.current = 1
    },
    // 选择病例模板获取对应病例列表
    async leftChange(id,link) {
      this.CaseId = id
      const data ={
        template_id : id,
        isPrint: this.CasePrintValue,
        audit_status : 'PASSED',
        isAudit : 1
      }
      this.adminLink=link
      await this.getCaseTemplates(data)
      await this.getCaseCount(id)
      this.selectValue='待打印'
      this.pagination.current = 1
    },
    // 打印
    download(id){
      window.open( headerConfig.api_header +'/api/admin/exportCaseTemplate/'+id)
    },
    // 状态修改
    async switchChange(id,isPrint){
      this.spinning = true;
      const data = {
        isPrint : isPrint
      }
      const Print = await putCaseCondition(id,data)
      if (Print.code === 0){
        const data ={
          template_id : this.CaseId,
          isPrint: this.CasePrintValue,
          audit_status :  'PASSED',
          isAudit : 1
        }
        await this.getCaseTemplates(data)
        this.$message.success('修改成功')
        await this.getCaseCount(this.CaseId)
      }
      this.spinning = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.CaseSettlement{
    background-color: #EEEEEE;
    display: flex;
    .CaseTemplate{
      background-color: white;
    }
  }
  .CaseData{
    background-color: white;
    width: 100%;
    padding: 15px;
    margin-left: 10px;
    min-height: 700px;
  }
  .CaseTables{
    width: 95%;
    margin: 0 auto;
  }
</style>
